import Vue from 'vue';
import router from '@/router/index';

export default {
  /**
   * Get list of Store
   */
  async getAllStores(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/listStores`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/listStores`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      storeName: query.name ?? '',
      storeNo: query.remarks ?? ''

    }
    try {
      //const response = JSON.parse('{"stores":[{"lastUpdated":"2022-01-26 10:06:49.0","storeNo":"0000","dateCreated":"2022-01-26 10:06:49.0","storeName":"test","id":2,"enabled":false,"remarks":""},{"lastUpdatedBy":"janchristian@apollo.com.ph","lastUpdated":"2022-01-24 18:01:46.0","storeNo":"0166","dateCreated":"2022-01-24 14:23:45.0","id":1,"enabled":true,"remarks":"Enabled by: janchristian@apollo.com.ph"}],"pageSize":1,"message":"Success","statusCode":"200"}');
      //return response;
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of stores." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Disable Store
   */
   async enableStore(item) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/enableStore`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/enableStore`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      id: item.id,
      storeNo: item.storeNo
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error disabling store." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Get list of Updatable Tables
   */
   async getAllUpdatableTables(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/listUpdatableTables`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/listUpdatableTables`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString()

    }
    try {
      //const response = JSON.parse('{"pageSize":1,"message":"Success","updatableTables":[{"searchableFields":"store_no, threshold_type","lastUpdated":"2022-01-27 11:18:35.0","dateCreated":"2022-01-27 11:18:35.0","allowedRoles":"ROLE_UPDATE_TABLE,ROLE_UPDATE_THRESHOLD","description":"description store_threshold description","id":1,"tableName":"store_threshold"}],"statusCode":"200"}');
      //return response;
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of updatable tables." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Get list of Table Values
   */
   async getAllTableValues(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/listTableData`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/listTableData`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      tableName: query

    }
    try {
      // const response = JSON.parse('{"pageSize":1,"storeThresholds":[{"storeNo":"0166","disableLimit":5,"disableCount":0,"storeName":"test","id":1,"defaultThreshold":2000,"thresholdEnabled":true,"thresholdType":"cb.daily.total.count","additionalThreshold":3000},{"storeNo":"0166","disableLimit":3,"disableCount":5,"id":2,"defaultThreshold":1000,"thresholdEnabled":true,"thresholdType":"cb.daily.total.amount","additionalThreshold":1000}],"message":"Success","statusCode":"200"}');
      // return response;
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of table values." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Get list of Table Values
   */
   async UpdateTableValue(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/updateTableData`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/updateTableData`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      // const response = JSON.parse('{"storeThreshold":{"storeNo":"0166","disableLimit":5,"disableCount":0,"storeName":"test","id":1,"defaultThreshold":2000,"thresholdEnabled":true,"thresholdType":"cb.daily.total.count","additionalThreshold":3000},"message":"Success","statusCode":"200"}');
      // return response;
      const response = await Vue.axios.put(url, params, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of updatable tables." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  getError(error) {
    if(error && error.response && error.response.data) {
      console.log(error.response)
      if(error.response.data.message) {
        return { error: error.response.data.message + ""};
      } else if(error.response.data.error) {
        return { error: error.response.data.error + ""};
      } else {
        return { error: error.response.data + ""};
      }
    } else {
      return { error: error + "" };
    }
  },
}
